var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', {
    staticClass: "nav-item has-sub",
    class: {
      'open': _vm.isOpen,
      'disabled': _vm.item.disabled,
      'sidebar-group-active': _vm.isActive
    }
  }, [_c('b-link', {
    staticClass: "d-flex align-items-center",
    attrs: {
      "to": _vm.item.link
    },
    on: {
      "click": function () {
        return _vm.updateGroupOpen(!_vm.isOpen);
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": _vm.item.icon || 'CircleIcon',
      "size": "24"
    }
  }), _c('span', {
    staticClass: "menu-title text-truncate"
  }, [_vm._v(_vm._s(_vm.t(_vm.item.title)))]), _vm.item.tag ? _c('b-badge', {
    staticClass: "mr-1 ml-auto",
    attrs: {
      "pill": "",
      "variant": _vm.item.tagVariant || 'primary'
    }
  }, [_vm._v(" " + _vm._s(_vm.item.tag) + " ")]) : _vm._e()], 1), _c('b-collapse', {
    staticClass: "menu-content",
    attrs: {
      "tag": "ul"
    },
    model: {
      value: _vm.isOpen,
      callback: function ($$v) {
        _vm.isOpen = $$v;
      },
      expression: "isOpen"
    }
  }, _vm._l(_vm.item.children, function (child) {
    return _c(_vm.resolveNavItemComponent(child), {
      key: child.header || child.title,
      ref: "groupChild",
      refInFor: true,
      tag: "component",
      attrs: {
        "item": child
      }
    });
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }